/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
@import url("https://fonts.googleapis.com/css?family=Exo:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// @import "~angular-bootstrap-md/scss/mdb-free.scss";

//Toastr Bootstrap
@import "~ngx-toastr/toastr-bs4-alert";

.toast {
  opacity: 1;
}

// Theme customization
@import "theme/theme";

@import "theme/utils.widths.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

*[disabled] {
  cursor: not-allowed;
}

html,
body,
[class^="mat"],
.mat-card {
  font-family: "Exo";
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  // font-style: italic;
  padding-bottom: 8px;
  //border-bottom:2px solid black;
  line-height: 1.5;
  // text-transform:uppercase;
}

a {
  cursor: pointer;
}

.fa {
  font-family: "FontAwesome" !important;
  font-weight: normal;
}

.b-0 {
  border: none !important;
}

.pointer {
  cursor: pointer !important;
}

.font-sm {
  font-size: 0.8rem !important;
}

.nav-link {
  display: inline-block;
}

.dropdown-item.active {
  background: white;
  color: $darkgrey;
}

.list-group-item.active {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item.active:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

ngb-typeahead-window.dropdown-menu {
  float: none;
  position: static;
  max-height: 300px;
  overflow: scroll;
  padding: 0;

  button {
    padding: 0;
    border-radius: 0;
    border: none;
    border-bottom-width: 1px;
  }
}

.submenu {
  background: $htblue;
  color: white;
}

.mat-tooltip {
  border-radius: 8px !important;
}

.agency-footer {
  background: $darkorange;
  color: white;
}

app-generic .colorOverlay {
  background: $blue !important;
  background: -moz-linear-gradient(-45deg, $blue 0%, $blue 40%, $darkblue 100%) !important;
  background: -webkit-linear-gradient(-45deg, $blue 0%, $blue 40%, $darkblue 100%) !important;
  background: linear-gradient(135deg, $blue 0%, $blue 40%, $darkblue 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='$darkblue', GradientType=0.8) !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
}

.navbar[_ngcontent-c2] {
  margin-bottom: 0rem !important;
}

.navbar[_ngcontent-c3] {
  margin-bottom: 0rem !important;
}

table.table.table-hover.table-bordered,
ts-ticket-list-item .ticket-list-item {
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.75);
}

table.table.table-hover.table-bordered {
  background: white;
}

// .navbar-dark .navbar-toggler {
//     color:white !important;
//     border:none !important;
// }

.card,
ts-event-card .date-time {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.agencyLogo {
  width: 100px;
  height: 100px;
  background: transparent;
  margin-bottom: -100px;
  z-index: 100;
}

.breadcrumb {
  background: white;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  font-size: 0.9rem;
}

.breadcrumb-item a {
  color: $lightblue !important;
}

.breadcrumb-item.active {
  color: $grey !important;
}

.modal-backdrop.show {
  opacity: 0.8;
}

ngb-modal-window {
  .modal-header-color {
    min-height: 150px;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 40%,
      rgba(25, 11, 111, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 40%,
      rgba(25, 11, 111, 0.8) 100%
    );
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 40%, rgba(25, 11, 111, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2014cc', endColorstr='#190b6f', GradientType=0.8);
    padding: 20px;
    border-radius: 3px 3px 0 0;

    .modal-agency-header {
      padding: 20px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.5);

      .modal-agency-logos {
        img {
          width: 80px;
          height: 80px;
        }

        .versus {
          margin-top: 29px;
          border-radius: 50%;
          height: 3rem;
          width: 3rem;
          line-height: 3rem;
          font-size: 1.2rem;
          font-weight: 600;
          border: 2px solid white;
          color: white;
          background: #000000;
        }
      }
    }
  }

  .discount-message {
    font-size: 0.7rem;
    color: $darkgrey;
  }

  .price.title {
    font-weight: bold;
  }

  .price.discount.has-discount {
    color: red;
    font-weight: bold;
    display: block;
  }

  .price.has-discount {
    color: initial;
    font-weight: normal;
    position: relative;
    display: inline-block;

    div {
      position: absolute;
      width: 100%;
      top: 9px;
      border-top: 2px solid red;
    }
  }

  .modal-close-button {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0.8;
  }

  .quantity-selector {
    border-radius: 6px;
    border: 2px solid $darkgrey;
    font-weight: 500;
    // font-size:3rem;
    // line-height:5rem;
    // padding:20px;

    .quantity,
    .btn {
      font-size: 2.4rem;
      line-height: 3rem;
      font-weight: 700;
    }

    .quantity-decrease {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .quantity-increase {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .step {
    background: $blue;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    line-height: 2.1rem;
    font-weight: bold;
    color: white;
  }

  .description {
    font-size: 0.8rem;
  }
}

.background-color {
  background: #e5e5e5;
  min-height: calc(100vh - 140px);
}

@media (max-width: 575px) {
  .background-color {
    min-height: calc(100vh);
  }
}

.table-hover {
  tr td {
    cursor: pointer;
  }
}

.timer {
  background: $lightblue;
  color: white;
  vertical-align: text-top;

  .time {
    border-radius: 4px;
    background: white;
    color: $lightblue;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  .timer {
    .description {
      padding: 4px 0;
      font-size: 0.6rem;
      line-height: 1rem;
    }
    .time {
      font-size: 1.2rem;
    }
  }
}

.mat-badge-content {
  width: 17px !important;
  height: 17px !important;
  line-height: 17px !important;
  font-size: 0.6rem !important;
  background-color: $htblue !important;
  color: white !important;
}

@media (min-width: 992px) {
  .mat-badge-content {
    background-color: $htblue !important;
    color: white !important;
  }
}

.background {
  position: absolute;
  top: 112px;
  width: 100%;
  height: calc(83vw / 2);
  z-index: 1;
  opacity: 0.8;
  display: none;
}

.table {
  background: white;
}

@media (max-width: 575px) {
  .background {
    top: 200px;
  }
}

@media (min-width: 575px) {
  .background {
    top: 141px;
  }
}

@media (min-width: 768px) {
  .background {
    top: 141px;
  }
}

@media (min-width: 992px) {
  .background {
    top: 112px;
  }
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #190b6f;
}

eco-fab-speed-dial-trigger .mat-fab.mat-primary,
eco-fab-speed-dial-trigger .mat-flat-button.mat-primary,
eco-fab-speed-dial-trigger .mat-mini-fab.mat-primary,
eco-fab-speed-dial-trigger .mat-raised-button.mat-primary {
  background-color: $darkblue;
}

button {
  touch-action: manipulation;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

ngb-modal-window {
  box-shadow: none;
  padding-top: 0;
  /* Status bar height on iOS 10 */
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top);
}

mat-calendar-header button {
  box-shadow: none;
}

ngb-typeahead-window button {
  box-shadow: none;
}

app-login .home-container,
app-auth-register .home-container,
app-auth-sign-up .home-container,
app-auth-forgot-password .home-container,
app-reset-password .home-container {
  min-height: calc(100vh - 112px);
  margin-top: 0px;
  padding-top: 50px;
}

*,
.tappable .not-tappable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
.tappable,
a,
button,
select,
option,
.card {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.btn-facebook {
  background: #3b5998;
  &:hover,
  &:active,
  &:focus {
    background: #6d84b4;
  }
}

ts-event-header-list-item {
  position: relative;
}

.modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ng-dropdown-panel.ng-select-bottom {
  left: 0;
}

.cdk-overlay-container {
  z-index: 10000;
}

// Added Seat Map CSS here due to how styles are generated and encapsulated for the reserved seats component. Will remove once workaround is figured out. - ELH
.seat-map {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  position: relative;
  height: 400px;

  .drag-scroll-content[_ngcontent-c16] {
    width: 100% !important;
  }

  .icon {
    color: $blue;
    fill: $blue;
    stroke: $blue;

    &.reserved {
      color: $lightgrey;
      fill: $lightgrey;
      stroke: $darkgrey;
    }

    &.blocked {
      color: $lightgrey;
      fill: $lightgrey;
      stroke: $darkgrey;
    }

    &.available {
      color: $lightblue;
      fill: $lightblue;
      stroke: $lightblue;
    }

    &.general {
      color: #e7e6e8;
      fill: #e7e6e8;
      stroke: $darkgrey;
    }
  }

  .seat-map-container {
    text-align: center;
    width: 100%;
    background: transparent;
    transform: (2, 2);
    display: inline-block;
    min-height: 100%;
    transition: all 0.5s ease-in-out;

    .map {
      display: inline-block;
      margin: 20px auto;
      min-width: 100%;
      min-height: 100%;
    }

    #Layer_1 {
      margin: 0 auto;
      text-align: center;
      transition: all 0.5s ease-in-out;

      .section {
        cursor: pointer;
        fill: #e7e6e8;
        stroke: #b7b6b8;
        stroke-miterlimit: 10;
        stroke-width: 1px;

        .title {
          cursor: pointer;
        }
      }

      &:hover .section {
        stroke-width: 6px;

        .title {
          stroke-width: 1px;
        }
      }

      .section.field {
        cursor: not-allowed;
        fill: #b7b6b8;
        stroke-miterlimit: 10;
        stroke-width: 1px;

        .title.field {
          cursor: not-allowed;
        }
      }

      .section.general,
      .title.general {
        cursor: not-allowed;
      }
    }

    &.zoom0 #Layer_1 {
      width: 70% !important;
      height: 70% !important;
      transform: (2, 2);
    }
    &.zoom1 #Layer_1 {
      width: 80% !important;
      height: 80% !important;
      transform: (2, 2);
    }
    &.zoom2 #Layer_1 {
      width: 95% !important;
      height: 95% !important;
      transform: (2, 2);
    }
    &.zoom3 #Layer_1 {
      width: 120% !important;
      height: 120% !important;
      transform: (2, 2);
    }
    &.zoom4 #Layer_1 {
      width: 140% !important;
      height: 140% !important;
      transform: (2, 2);
    }
    &.zoom5 #Layer_1 {
      width: 160% !important;
      height: 160% !important;
      transform: (2, 2);
    }
    &.zoom6 #Layer_1 {
      width: 180% !important;
      height: 180% !important;
      transform: (2, 2);
    }
    &.zoom7 #Layer_1 {
      width: 200% !important;
      height: 200% !important;
      transform: (2, 2);
    }
  }
}

@media (max-width: 991px) {
  #chatbot-chat {
    right: initial !important;
    left: 0px !important;
  }
}

.mat-calendar-body-cell {
  box-shadow: none;
}

.custom-date-class {
  background-color: rgba(255, 105, 0, 0.5) !important;
  border-radius: 100%;

  &.selected-week-class {
    background-color: rgba(255, 105, 0, 0.5) !important;
    border-radius: 100%;
  }

  &:before {
    content: "\f145" !important;
    width: 25px;
    height: 25px;
    background: $htblue;
    color: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 25px;
    font-family: "FontAwesome" !important;
    font-weight: normal;
    font-size: 14px;
  }

  &:after {
    content: unset !important;
  }
}

.selected-week-class {
  background-color: rgba(255, 105, 0, 0.1) !important;
  border-radius: 0%;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: $darkorange;
}

.mat-calendar-body-selected {
  background-color: transparent;
  color: inherit;
}

@media (max-width: 575px) {
  .custom-date-class {
    &:before {
      width: 21px !important;
      height: 21px !important;
      top: -8px !important;
      right: -8px !important;
      line-height: 21px;
      font-size: 11px;
      left: unset !important;
    }
  }
}

@media (min-width: 575px) {
  .custom-date-class {
    &:before {
      width: 21px !important;
      height: 21px !important;
      top: -8px !important;
      right: -8px !important;
      line-height: 21px;
      font-size: 11px;
      left: unset !important;
    }
  }
}

@media (min-width: 768px) {
  .custom-date-class {
    &:before {
      width: 25px !important;
      height: 25px !important;
      top: 0 !important;
      right: 0 !important;
      line-height: 25px;
      font-size: 14px;
      left: unset !important;
    }
  }
}

@media (min-width: 992px) {
  .custom-date-class {
    &:before {
      width: 25px !important;
      height: 25px !important;
      top: 0 !important;
      right: 0 !important;
      line-height: 25px;
      font-size: 14px;
      left: unset !important;
    }
  }
}

@media (min-width: 1200px) {
  .custom-date-class {
    &:before {
      width: 25px !important;
      height: 25px !important;
      top: 0 !important;
      right: 0 !important;
      line-height: 25px;
      font-size: 14px;
      left: unset !important;
    }
  }
}

mat-sidenav {
  height: auto;
  width: 100%;
  margin: 52px 0;
  padding: 5px 0;
}

mat-sidenav .mat-list-base .mat-list-item .mat-list-item-content,
mat-sidenav .mat-list-base .mat-list-option .mat-list-item-content {
  display: flex;
  justify-content: center;
  color: black;
}

.mat-drawer {
  bottom: auto !important;
}

.mat-drawer-opened {
  height: auto !important;

  mat-sidenav-content {
    overflow: hidden;
  }
}

svg.ticketSpicketSVG * {
  color: white;
  fill: white;
  stroke: none !important;
}

.container {
  margin-bottom: 0 !important;
}

::ng-deep .mat-checkbox .mat-checkbox-frame {
  border-color: $green;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $htblue;
}

ngx-spinner .overlay {
  background-image: url('./assets/imgs/ticketspicket-bg.svg');
  background-position: center center;
  background-size: cover;
}

.loading-text {
  text-align: center !important;

  p {
    font-size: 16px !important;
    text-transform: uppercase !important;
  }
}
.search-input .dropdown-menu.show {
  right: 40% !important;
}

ng-select {
  .ng-placeholder {
    margin-right: auto;
    margin-left: auto;
    color: #6d6e71 !important;
  }

  .ng-dropdown-panel-items {
    text-align: left;
  }
}

.ng-select.home-page-search .ng-select-container {
    height: 55px;
    font-size: 20px;
    color: #6d6e71;

    .ng-value-container .ng-input {
        top: 15px;
    }

}

.ng-select.home-page-search .ng-option {
    padding: 5px;
    color: #6d6e71;
}

.modal {
  z-index: 1060 !important;
}

.modal-body {
    button.close {
        box-shadow: none;
    }
}

.fa-overide {
    font-weight: 900;
}

.mat-drawer-backdrop {
    background: transparent !important;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px inset;
}
