/*
 * Application global variables.
 */

// Set Font Awesome font path


// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$htblue: #005CB9;
$blue: #2014CC;
$lightblue: #0075E0;
$darkblue: #190b6f;
$lightgrey: #f8f8f8;
$grey: #717171;
$darkgrey: #3A3A3A;
$green: #1C9614;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #ff6900;
$darkorange : #e14b00;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$theme-colors: (
  primary: $blue,
  secondary: $htblue,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

$blue-100 : #7e76f2;
$blue-200 : #6157ef;
$blue-300 : #4438ec;
$blue-400 : #2719e8;
$blue-500 : #2014CC;
$blue-600 : #1b11ad;
$blue-700 : #160e8e;
$blue-800 : #110b6f;
$blue-900 : #0d0850;
$indigo-100 : #7e76f2;
$indigo-200 : #6157ef;
$indigo-300 : #4438ec;
$indigo-400 : #2719e8;
$indigo-500 : #2014CC;
$indigo-600 : #1b11ad;
$indigo-700 : #160e8e;
$indigo-800 : #110b6f;
$indigo-900 : #0d0850;
$orange-50: #ffebdd;
$orange-100: #ffd7bb;
$orange-200: #ffc399;
$orange-300: #ffaf77;
$orange-400: #ff9144;
$orange-500: #ff7d22;
$orange-600: #ff6900;
$orange-700: #dd5b00;
$orange-800: #bb4d00;
$orange-900: #993f00;
$orange-A100: #ffd180;
$orange-A200: #ffab40;
$orange-A400: #ff9100;
$orange-A700: #ff6d00;
$primary: #005CB9;
$info: #2196f3;
$success: #4caf50;
$warning: #ffc107;
$danger: #f44336;
$accent: #7e57c2;
$light: #eaebec;
$dark: #475069;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
